<template>
  <v-row class="statis">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">Messages Statistics</div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-sm-15">
        <div class="accent py-2 px-sm-10 px-2 custom">
          <v-card width="100%" color="accent" flat class="d-flex flex-md-nowrap flex-wrap justify-space-between">
            <div>
              <v-select
                v-model="date"
                :items="periods"
                item-text="title"
                item-value="name"
                dense
                outlined
                hide-details
                color="input"
                height="40"
                @change="getData"
                class="mr-5 my-sm-0 my-1"
              ></v-select>
              <span v-if="date == 'custom'">
                <v-menu v-model="modalFrom" :close-on-content-click="false" fixed transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mr-5 my-sm-0 my-1"
                      v-model="dateFrom"
                      placeholder="From"
                      hide-details
                      append-icon="mdi-calendar"
                      readonly
                      dense
                      outlined
                      required
                      v-bind="attrs"
                      v-on="on"
                      color="input"
                      height="40"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :max="dateTo"
                    color="primary"
                    v-model="dateFrom"
                    @input="modalFrom = false"
                    @change="getData"
                  ></v-date-picker>
                </v-menu>
                <v-menu v-model="modalTo" :close-on-content-click="false" fixed transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="my-sm-0 my-1"
                      v-model="dateTo"
                      placeholder="To"
                      hide-details
                      append-icon="mdi-calendar"
                      readonly
                      dense
                      outlined
                      required
                      v-bind="attrs"
                      v-on="on"
                      color="input"
                      height="40"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :min="dateFrom"
                    :max="new Date().toISOString().substr(0, 10)"
                    color="primary"
                    v-model="dateTo"
                    @input="modalTo = false"
                    @change="getData"
                  ></v-date-picker>
                </v-menu>
              </span>
            </div>
            <div class="d-flex align-center my-2">
              <div>Total count: {{ data.total_count }}</div>
              <v-divider class="mx-3" vertical></v-divider>
              <div>Total price: {{ data.total }}</div>
            </div>
          </v-card>
        </div>
        <div>
          <v-card flat height="70vh" min-height="260" v-if="nothing" class="pa-3 d-flex align-center justify-center flex-column">
            <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
            <div class="text-center text-h5 font-weight-bold mt-8">Sorry, but nothing found</div>
          </v-card>
          <v-card
            flat
            height="70vh"
            min-height="260"
            v-else-if="block || !data.messages.length"
            class="pa-3 d-flex align-center justify-center flex-column"
          >
            <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
            <div class="text-center text-h6 font-weight-bold mt-8">Looks like there are no statistics for your account.</div>
            <!-- <div class="text-center opasity--text font-weight-regular mt-2">Please, contact support to resolve this issue.</div> -->
          </v-card>
          <div v-else>
            <v-data-table
              hide-default-footer
              fixed-header
              :headers="headers"
              :items="data.messages"
              :height="'calc(100vh - 247px)'"
              class="d-sm-block d-none p-table"
            >
              <template v-slot:item.mcc="{ item }">
                <span>{{ item.mcc }}/{{ item.mnc }}</span>
              </template>
              <template v-slot:no-data>Sorry, there were no messages during this period.</template>
            </v-data-table>
            <div class="d-block d-sm-none">
              <div v-for="item in data.messages" :key="item.id" class="px-4 pt-2">
                <div v-for="head in headers" :key="head.value" class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">{{ head.text }}</div>
                  <div class="font-weight-medium f18">{{ item[head.value] }}{{ head.value == 'mcc' ? `/${item.mnc}` : '' }}</div>
                </div>
                <v-divider></v-divider>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      date: this.$route.query.date || 'last_7_days',
      dateFrom: this.$route.query.date_from || new Date().toISOString().substr(0, 10),
      dateTo: this.$route.query.date_to || new Date().toISOString().substr(0, 10),
      modalFrom: false,
      modalTo: false,
      selectedPeriod: 0,
      load: true,
      block: true,
      nothing: false,
      headers: [
        { text: 'Country', value: 'country', sortable: false },
        { text: 'Operator', value: 'operator', sortable: false },
        { text: 'MCC/MNC', value: 'mcc', sortable: false },
        { text: 'Count', value: 'count', sortable: false },
        { text: 'Total', align: 'end', value: 'total', sortable: false },
      ],
      statusError: null,
      error: null,
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getPeriods');
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({ message: 'Error', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    valuePeriod(period, i) {
      this.selectedPeriod = i;
      this.date = period;
      this.getData();
    },
    async getData() {
      await this.$store
        .dispatch('getMessagesStatis', {
          date: this.date,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        })
        .then(() => {
          this.nothing = false;
          this.block = false;
        })
        .catch((e) => {
          this.getDataError(e);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.data.error[0] === 'account__not_found') {
        this.$store.dispatch('setMessagesStatis', {});
        this.block = true;
      } else if (e.response.data.error[0] === 'result__not_found') {
        this.$store.dispatch('setMessagesStatis', {});
        this.nothing = true;
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  watch: {
    periods() {
      this.periods.find((item, i) => {
        item.name == this.$route.query.date ? (this.selectedPeriod = i) : 0;
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.messagesStatis;
    },
    periods() {
      return this.$store.getters.periods;
    },
  },
  destroyed() {
    this.$store.dispatch('setMessagesStatis', {});
  },
};
</script>

<style lang="scss">
.statis {
  .custom {
    .v-input {
      display: inline-block;
      max-width: 160px;
      width: 100%;
      .v-input__append-inner {
        margin-top: 8px !important;
      }
    }
  }
}
</style>
